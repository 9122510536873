<template>
    <form action="">
        <div class="d-flex justify-content-between align-items-center">
            <h1>{{ $t("Quiz") }}</h1>
            <div>
                <a class="info-button btn btn-sm " v-if="warningMessage.length>0 && !canEdit" href="javascript:void(0);" @click="allowToEdit()"> <i class="eicon e-pencil"></i> <span>{{ $t(`Edit`) }}</span></a>
                <a class="danger-button btn btn-sm" href="javascript:void(0);" v-if="canRemove" @click="deleteAllQuestions()"> <i class="eicon e-delete"></i> <span>{{ $t(`Remove`) }}</span></a>
            </div>
        </div>
        <div class="form-note"><span class="text-capitalize">{{ $t("note") }}: </span>{{ $t("If you want to take a quiz please insert the Quiz time, marks per question and Quiz name here.") }} {{ $t("It will appear in the application process. (Example: 15 Minutes, 3 Marks per Question and Personality Test.)") }}</div>
        <template>
            <div class="alert fade show alert-warning text-center" role="alert" v-if="warningMessage.length>0">
                {{ warningMessage }}
            </div>
        </template>
        <template v-if="canEdit">
            <div class="section-title mt-5">{{ $t("Select an existing question set") }}</div>
            <div class="row form-group">
                <div class="col-md-6 mt-4">
                    <multiselect
                        id="quiz_set"
                        v-model="quiz_set"
                        :options="quizSetOptions"
                        :placeholder="$t('select a set.')"
                        track-by="id"
                        @input="selectQuestionSet"
                        label="name"
                    ></multiselect>
                </div>
            </div>
        </template>

        <div class="row form-group">
            <div class="col-md-4 mt-4">
                <label for="exam_duration" class="text-capitalize">{{ $t("Quiz Duration") }}</label>
                <input type="text" ref="exam_duration" v-model="form.exam_duration" id="exam_duration" class="form-control" :placeholder="$t('duration in minutes')" min="1" maxlength="5" :readonly="!canEdit"/>
                <error-message :message="$t(errors.exam_duration)"/>
            </div>
            <div class="col-md-4 mt-4">
                <label for="marks_per_question">{{ $t("Marks Per Question") }}</label>
                <input type="text" ref="marks_per_question" v-model="form.marks_per_question" id="marks_per_question" class="form-control" :placeholder="$t('Marks per question')" min="1" maxlength="5" :readonly="!canEdit"/>
                <error-message :message="$t(errors.marks_per_question)"/>
            </div>
        </div>

        <quiz-question
            v-for="(question, index) in form.questions"
            :key="index"
            :question="question"
            :errors="errors"
            :total-question="form.questions.length"
            @moveUp="moveUp($event)"
            @moveDown="moveDown($event)"
            @deleteQuestion="deleteQuestion($event)"
        >
        </quiz-question>

        <div class="section-gap">
            <button class="button primary-button text-capitalize" @click.prevent="addQuestion" v-if="canEdit">
                <span class="icon"><i class="eicon e-plus"></i></span>
                <span class="text-capitalize">{{ $t("add question") }}</span>
            </button>
        </div>
        <div class="section-gap d-flex justify-content-between">
            <router-link :to="{name: 'job.screening', currentJobId}" class="button semi-button-info text-capitalize mr-4">{{ $t("back") }}</router-link>
            <div class="d-flex">
                <button class="button semi-button-danger mr-4" @click="cancelEdit()" v-if="canEdit && !isEmpty">{{ $t("Cancel") }}</button>
                <submit-button :click="saveQuiz" :loading="isLoading" :disabled="isEmpty || !canEdit" v-if="isPublished || !canPublishJob">{{ $t("Save") }}</submit-button>
                <submit-button :click="saveQuiz" :loading="isLoading" :disabled="isEmpty || !canEdit" v-else>{{ $t("Save and Publish") }}</submit-button>
            </div>
        </div>
    </form>
</template>
<script>
import client from "../../app/api/Client";
import {getJobQuizData, getJobQuizMetaData, getQuestionSet} from "../../app/api/CommonRequest";
import {JOB} from "../../extra/constants";
import Multiselect from 'vue-multiselect';
import {EventBus} from "../../extra/event-bus";
import {CHECK_AND_PUBLISH_JOB} from "../../constants/events";
import {mapState} from "vuex";
import ErrorMessage from "../../components/common/ErrorMessage";
import {isEmpty as _isEmpty} from "lodash";
import {canPublishJob} from "../../config/permission";
import evalution from "../../services/validations/evalution"
import QuizQuestion from "../../components/questions/QuizQuestion";

export default {
    props: [
        'job'
    ],
    components: {
        ErrorMessage,
        QuizQuestion,
        Multiselect
    },
    data() {
        return {
            currentJob: {},
            editMode: false,
            isPublished: false,
            form: {
                job_id: null,
                exam_duration: '',
                marks_per_question: '',
                questions: []
            },
            quiz_set: null,
            quizSetOptions: [],
            questionTypeOptions: [],
            hasErrorInQuestions: false,
            errors: [],
            isLoading: false,
            warningMessage: '',
            canRemove: false
        }
    },
    computed: {
        ...mapState(['company']),
        currentJobId() {
            return this.$route.params.id;
        },
        isEmpty() {
            return !this.form.questions.length;
        },
        hasApplicant() {
            return this.currentJob.applicant_count > 0;
        },
        canEdit() {
            return this.editMode;
        },
        newQuizQuestion() {
            return _isEmpty(this.currentJob.quiz_group);
        },
        canPublishJob() {
            return canPublishJob();
        }
    },
    methods: {
        cancelEdit() {
            this.form.job_id = this.$route.params.id;
            this.findJobQuizMetaData();
            this.findJobQuizData();
        },

        allowToEdit() {
            let message = {
                title: this.$t("Confirmation"),
                body: `<div class="custom-delete">
                                <p class="text-center">${this.$t(' Only new applicants will get updated questions & marks will be on new question set. Old applicant\'s marks will not be changed.')}</p>
                                <br>
                                <p class="text-center">${this.$t('Are you sure you want to edit questions?')}</p>
                         </div>`
            };

            this.$dialog.confirm(message).then(() => {
                this.editMode = true;
            });
        },

        async deleteAllQuestions() {
            let message = {
                title: this.$t("Confirmation"),
                body: `<div class="custom-delete">
                                <p class="text-center">${this.$t(' Only new applicants will not be able to answer questions. Old applicant\'s marks will not be changed.')}</p>
                                <br>
                                <p class="text-center">${this.$t('Are you sure you want to delete questions?')}</p>
                         </div>`
            };

            await this.$dialog.confirm(message).then(() => {
                    client().delete(`/job/${this.currentJobId}/quiz`).then(({data}) => {
                        this.$toast.success(this.$t(data.message));
                        this.canRemove = false;
                        this.quiz_set = null;
                        this.form.exam_duration =null;
                        this.form.marks_per_question = null;
                    } ).catch(({data})=> {
                        this.$toast.error(this.$t(data.message));
                    }).finally(()=>{
                        this.form.questions = [];
                        this.findJobQuizMetaData();
                        this.findJobQuizData();
                    });
            });
        },

        moveUp(index, elem) {
            [this.form.questions[index], this.form.questions[index - 1]] = [this.form.questions[index - 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },

        moveDown(index, $event) {
            [this.form.questions[index], this.form.questions[index + 1]] = [this.form.questions[index + 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },


        selectQuestionSet() {
            if (!this.quiz_set) {
                this.$toast.error(this.$t('select a set.'));
            }

            getQuestionSet(this.quiz_set.id).then((data) => {
                this.form.questions = data.questions;
            });
        },

        validationErrorFocus() {
            let focusInputs = ['exam_duration', 'marks_per_question'];

            let focusKey = Object.keys(this.errors)[0];
            if(focusInputs.includes(focusKey)) {
                window.scrollTo(0,0);
                this.$refs[focusKey].focus();
            }
        },

        async saveQuiz() {
            this.validateJobQuiz();

            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            if (!this.form.job_id) {
                this.$toast.error(this.$t('invalid job id.'));
                return 0;
            }

            if (this.form.questions.length === 0) {
                this.$toast.error(this.$t('Question not added.'));
                return false;
            } else {
                this.validateQuestions();

                if (this.hasErrorInQuestions) {
                    return false;
                }
            }


            this.isLoading = true;
            try {
                this.errors = [];
                let {data} = await client().post(`/job/${this.currentJobId}/quiz`, this.form);
                this.canRemove = true;
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                    this.editMode =false;
                    if(this.canPublishJob) {
                        EventBus.$emit(CHECK_AND_PUBLISH_JOB);
                    }
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if(typeof err == 'undefined') {
                    this.isLoading = false;
                    return false;
                }
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },

        validateJobQuiz() {
            let {errors, hasErrorInQuestions} = evalution.validateJobQuiz(this.form);
            this.errors = errors;
            this.hasErrorInQuestions = hasErrorInQuestions;
        },

        validateQuestions() {
            let {hasErrorInQuestions} = evalution.validateQuestions(this.form.questions, true);
            this.hasErrorInQuestions = hasErrorInQuestions;
        },

        addQuestion() {
            this.validateJobQuiz();

            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            this.validateQuestions();

            if (this.hasErrorInQuestions) {
                return false;
            }

            this.form.questions.push({
                id: null,
                type: '',
                title: "",
                options: [
                    {
                        id: null,
                        title: '',
                    },
                    {
                        id: null,
                        title: '',
                    }
                ],
                answers: [],
                errors: [],
                isMultiple: false
            });
        },

        deleteQuestion(key) {
            let message = {
                title: this.$t('Confirmation'),
                body: this.$t('Are you sure, you want to delete this question?')
            };

            this.$dialog.confirm(message).then(() => {
                this.form.questions.splice(key, 1);
            });
        },

        checkEditMode() {
            if (this.currentJob.applicant_count > 0) {
                this.warningMessage = this.currentJob.applicant_count + ' ' + this.$t(`applicant(s) have already applied for this job. If you edit question, old applicant's marks will not be changed. Only new applicants will get new questions.`);
                this.editMode = false;
            } else {
                this.editMode = true;
            }
        },

        findJobQuizMetaData() {
            getJobQuizMetaData().then((data) => {
                this.quizSetOptions = data.quiz_set;
            });
        },

        findJobQuizData() {
            getJobQuizData(this.currentJobId).then((data) => {
                if (data.status === 'SUCCESS') {
                    this.currentJob = data.data;
                    this.form.job_id = this.currentJob.id;
                    if (this.currentJob.quiz_group) {
                        this.form.exam_duration = this.currentJob.quiz_group.exam_duration;
                        this.form.marks_per_question = this.currentJob.quiz_group.marks_per_question;
                        this.canRemove = true;
                    }
                    if (this.currentJob.questions.length) {
                        this.form.questions = this.currentJob.questions;
                    }
                    this.isPublished = this.currentJob.status === JOB.STATUS.PUBLISHED;
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            }).finally(() => {
                this.checkEditMode();
            });
        }
    },

    created() {
        this.form.job_id = this.$route.params.id;
        this.findJobQuizMetaData();
        this.findJobQuizData();
    }
}
</script>
